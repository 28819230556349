import React from "react";
import UserIcon from "./../../assets/Im User 2.svg";
import CopyToClipboardButton from "./CopyToClipboardButton";

export default function UserMessage({ text }) {
  return (
    <div className="message-container">
      <div className="usermessageShow">
        <div className="userImg">
          <img src={UserIcon} alt="User Icon" className="user-icon" />
        </div>
        <span className="user-message">
          <div>{text}</div>
          <div className="user-copy-container">
            <div>
              <span title="Copy Message" className="play-pauseBtn">
                <CopyToClipboardButton copyText={text} />
              </span>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}
