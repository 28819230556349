import React, { useState, useEffect } from "react";
import BotIcon from "./../../assets/BotIcon 1.png";
import cookie from "react-cookies";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";
import { useAudioPlayer } from "react-use-audio-player";
import API from "../ChatbotAPI";
import { LuDownload } from "react-icons/lu";
import CopyToClipboardButton from "./CopyToClipboardButton";

export default function BotMessage({
  fetchMessage,
  playOnce,
  audio,
  msgsRef,
  botIndex,
  fetchAudio,
  setDisabled,
  audioRefs,
}) {
  const [isLoading, setLoading] = useState(
    botIndex >= 0 && msgsRef.current[botIndex] ? false : true
  );
  const [message, setMessage] = useState(
    botIndex >= 0 && msgsRef.current[botIndex] ? msgsRef.current[botIndex] : ""
  );
  const profile = cookie.load("profile");
  const [play, setPlay] = useState(false);
  const [donePlaying, setDonePlaying] = useState(false);
  const getVolume = () => localStorage.getItem("hr-volume");
  const audioPlayer = useAudioPlayer();
  const [isPdf, setIsPdf] = useState(false);
  const [currEmail, setCurrEmail] = useState(null);
  const [showDownload, setShowDownload] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);

  // console.log("audiorefs -- ", audioRefs.current)

  const stopAllAudio = () => {
    if (audio.current && audio.current.length) {
      audio.current.forEach((item) => {
        if (item) {
          item?.audioPlayer?.pause();
          item?.setPlay(false);
        }
      });
    }
  };

  const extractEmail = (text) => {
    // Regular expression for matching email addresses
    const emailRegex = /Email:\s*([^\s]+)/i;
    const match = text.match(emailRegex);
    return match ? match[1] : null;
  };

  const extractCandidateName = (text) => {
    const nameRegex1 = /Candidate Name:\s*([^\n]+)/i;
    const nameRegex2 = /Name:\s*([^\n]+)/i;
    const match1 = text.match(nameRegex1);
    const match2 = text.match(nameRegex2);
    return match1
      ? match1[1].trim()
      : match2
      ? match2[1].trim()
      : "No name found";
  };

  const downloadThePDF = (base64PDF) => {
    // Convert Base64 string to a byte array
    const byteCharacters = atob(base64PDF); // Decode Base64 to binary string
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    // Create a Blob from the byte arrays
    const blob = new Blob(byteArrays, { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const a = document.createElement("a");
    a.href = url;
    a.download = "download.pdf"; // Specify the desired file name
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Clean up the URL object
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (isPdf && currEmail) {
      const pdfDownload = async () => {
        const resp = await API.getPDFReponse({
          candidate_name: null,
          candidate_email: [currEmail],
          pdf: true,
        });
        if (resp) {
          setShowDownload(true);
          setFeedbacks(resp?.responseData[0]?.feedback);
        }
      };

      pdfDownload();
    }
  }, [currEmail]);

  useEffect(() => {
    async function loadMessage() {
      const msg = await fetchMessage();
      if (
        msg?.flag &&
        msg?.response !== "No feedback found for the candidate."
      ) {
        setIsPdf(true);
        setCurrEmail(extractEmail(msg?.response));
      }
      if (msg?.response) {
        if (playOnce) {
          audioRefs.current.push(
            "https://myy-audio-bucket.s3.eu-north-1.amazonaws.com/Messagee.mp3"
          );
          audioPlayer.load(
            audioRefs.current[0] ||
              "https://myy-audio-bucket.s3.eu-north-1.amazonaws.com/Messagee.mp3",
            {
              autoplay: true,
            }
          );
          audioPlayer.setVolume(getVolume());
          audioPlayer.play();
          audio.current.push({
            audioPlayer,
            setPlay: (params) => setPlay(params),
          });
        } else {
          const res2 = await fetchAudio(msg?.response);
          if (res2) {
            const blob = new Blob([res2], { type: "audio/mp3" });
            const url = URL.createObjectURL(blob);
            const blobWithExtension = `${url}#t=0.001&.mp3`;
            audioPlayer.load(blobWithExtension, {
              onplay: () => {
                setPlay(true);
              },
              onend: () => {
                setPlay(false);
              },
            });
            audioPlayer.setVolume(getVolume());
            audioPlayer.play();
            audio.current.push({
              audioPlayer,
              setPlay: (params) => setPlay(params),
            });
            audioRefs.current.push(blobWithExtension);
          }
        }

        setLoading(false);
        setDisabled(false);
        setMessage(msg?.response);
        msgsRef.current.push(msg?.response);
      }
    }
    botIndex >= 0 && !msgsRef.current[botIndex] && loadMessage();
  }, [fetchMessage]);

  const extractInfo = (data) => {
    // console.log("data -- ", data);
    // Define regex patterns for each piece of information
    const verdictPattern = /Verdict:\s*(.*)/;
    const ratingPattern = /Overall Rating:\s*([\d.]+)/;
    const feedbackPattern = /Overall Feedback:\s*([\s\S]*?)\s*Interview Date:/;
    const datePattern = /Interview Date:\s*(\d{4}-\d{2}-\d{2})/;

    // Extract information using regex patterns
    const verdictMatch = data?.match(verdictPattern);
    const ratingMatch = data?.match(ratingPattern);
    const feedbackMatch = data?.match(feedbackPattern);
    const dateMatch = data?.match(datePattern);

    console.log("verdict --- ", dateMatch, feedbackMatch)

    const verdict = verdictMatch ? verdictMatch[1]?.trim() : "";
    const rating = ratingMatch ? ratingMatch[1]?.trim() : "";
    const feedback = feedbackMatch ? feedbackMatch[1]?.trim() : "";
    const date = dateMatch ? dateMatch[1]?.trim() : "";

    return {
      Verdict: verdict,
      OverallRating: rating,
      OverallFeedback: feedback,
      InterviewDate: date,
    };
  };

  // const parsePDF = (data) => {
  //   const entries = data?.split(/\n(?=\d+\.)/).slice(1); // Splitting on each feedback entry
  //   return entries?.map((entry) => {
  //     const extractedInfo = extractInfo(entry);
  //     console.log("extracted info -- ", extractedInfo);
  //     return {
  //       ...(extractedInfo && typeof extractedInfo === "object"
  //         ? extractedInfo
  //         : null),
  //     };
  //   });
  // };

  const parsePDF = (data) => {
    if (!data) {
      // console.error("Data is undefined or null");
      return [];
    }
    
    let entries = data.split(/\n\s*\n/).filter(Boolean);
    entries = [entries[0]]
    return entries.map((entry) => {
      const extractedInfo = extractInfo(entry);
      return {
        ...(extractedInfo && typeof extractedInfo === "object"
          ? extractedInfo
          : null),
      };
    });
  };
  

  const handleDownload = (index) => {
    if (index >= 0 && feedbacks[index]) {
      downloadThePDF(feedbacks[index]?.pdf);
    }
  };

  const handlePDF = (data) => {
    const feedbackEntries = parsePDF(data);
    // console.log("feedbacks --- ", feedbacks)
    return (
      <>
        <div>
          <span style={{ fontWeight: "500" }}>Candidate Name:</span>{" "}
          {extractCandidateName(data)} <br />
          <span style={{ fontWeight: "500" }}>Email:</span> {extractEmail(data)}
        </div>
        <br />
        <div>
          <div style={{ fontWeight: "500" }}>Feedback Entries :</div>
          <div>
            <ol>
              {feedbacks?.map((entry, index) => (
                <li key={index}>
                  <span style={{ fontWeight: "500" }}>Interview Date:</span>{" "}
                  {entry?.interviewDate?.split("T")[0]} &nbsp;&nbsp;&nbsp;{" "}
                  {showDownload && (
                    <span onClick={() => handleDownload(index)}>
                      <LuDownload className="download-pdf-icon" />
                    </span>
                  )}{" "}
                  <br />
                  <span style={{ fontWeight: "500" }}>Verdict:</span>{" "}
                  {entry?.verdict} <br />
                  <span style={{ fontWeight: "500" }}>
                    Overall Rating:
                  </span>{" "}
                  {entry?.overAllRating} <br />
                  <span style={{ fontWeight: "500" }}>Feedback:</span>{" "}
                  {entry?.overAllFeedback} <br />
                  {feedbacks?.length - 1 !== index && <br />}
                </li>
              ))}
            </ol>
          </div>
        </div>
      </>
    );
  };

  const makeBold = (text) => {
    return (
      <span>
        {text && text?.split("**")?.length > 1 ? (
          <b>{text?.split("**")[1]?.split("**")[0]}</b>
        ) : (
          text
        )}
      </span>
    );
  };

  return (
    <div className="message-container">
      <div className="alignItem">
        <img
          src={BotIcon}
          width={32}
          height={32}
          alt="Bot Icon"
          className="bot-icon"
        />
        <div
          className="bot-message"
          style={{ padding: isLoading && "3px 14px" }}
        >
          {isLoading ? (
            <div className="loader-container">
              <div className="loader-head">
                Please wait while we're fetching details{" "}
              </div>
              <div className="stage">
                <div className="dot-pulse"></div>
              </div>
            </div>
          ) : message ===
            `Hey ${profile?.givenName} ${profile?.familyName}, How can I help you?` ? (
            message
          ) : (
            <div style={{ display: "flex" }}>
              <div>
                {isPdf
                  ? handlePDF(message)
                  : message?.split("\n")?.map((item) => (
                      <>
                        <div>{makeBold(item)}</div>
                        <br />
                      </>
                    ))}
                {/* {!playOnce && (
                  <>
                    <br />
                    <br />
                    "For more info, refer to the"&nbsp;&nbsp;
                    <a className="anchor"
                      target="_blank"
                      href="https://docs.google.com/document/d/1eHSTDeOn4VarU-cZjxNaH8HxxsZJLHJR-BYRR3d_ktg/edit"
                    >
                      HR Manual
                    </a>
                  </>`
                )} */}
                {!playOnce && !donePlaying && (
                  <div className="audio-container">
                    <div>
                      <span>
                        {play === false ? (
                          <span
                            title="Play Audio"
                            className="play-pauseBtn"
                            onClick={() => {
                              stopAllAudio();
                              setPlay(true);
                              audio.current[botIndex]?.audioPlayer?.pause();
                              audio.current[botIndex]?.audioPlayer?.setVolume(
                                getVolume()
                              );
                              audio.current[botIndex]?.audioPlayer?.play();
                            }}
                          >
                            <PlayArrowIcon className="icon" />
                          </span>
                        ) : (
                          <span
                            title="Pause Audio"
                            className="play-pauseBtn"
                            onClick={() => {
                              setPlay(false);
                              audio.current[botIndex]?.audioPlayer?.pause();
                            }}
                          >
                            <PauseIcon className="icon" />
                          </span>
                        )}
                      </span>
                      <span
                        title="Replay Audio"
                        className="play-pauseBtn"
                        onClick={() => {
                          stopAllAudio();
                          setPlay(true);
                          audio.current[botIndex]?.audioPlayer?.stop();
                          audio.current[botIndex]?.audioPlayer?.setVolume(
                            getVolume()
                          );
                          audio.current[botIndex]?.audioPlayer?.play();
                        }}
                      >
                        <ReplayIcon className="icon" />
                      </span>
                      <span title="Copy Message" className="play-pauseBtn">
                        <CopyToClipboardButton copyText={message} />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
