import React, { useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import { FaMicrophone } from "react-icons/fa";

export default function Input({ onSend, startRecording, disabled }) {
  const [text, setText] = useState("");

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleSend = (e) => {
    e.preventDefault();
    if(!text?.trim() || disabled) return;
    onSend(text);
    setText("");
  };

  const handleCancelInput = () => {
    setText("");
  };

  return (
    <>
      <div className="inputType">
        <form onSubmit={handleSend}>
          <input
            type="text"
            onChange={handleInputChange}
            value={text}
            placeholder={"Enter your message here..."}
          />
        </form>
        <div className="button-container">
          <button className="mic_btn">
            {!text ? (
              disabled ? (
                <span style={{ opacity: "0.4" }} title="Start Recording">
                  <FaMicrophone className="mic-icon" />
                </span>
              ) : (
                <span title="Start Recording" onClick={startRecording}>
                  <FaMicrophone className="mic-icon" />
                </span>
              )
            ) : (
              <span
                title="Cancel"
                className="inputCross"
                onClick={handleCancelInput}
              >
                <CloseIcon
                  style={{
                    color: "#00a99d",
                    zIndex: "200000",
                    position: "relative",
                  }}
                  className="saregama-close-icon"
                />
              </span>
            )}
          </button>
          {text && !disabled ? (
            <div title="Send" className="btn-container" style={{cursor: 'pointer'}} onClick={handleSend}>
              <SendIcon className="send-btn2" />
            </div>
          ) : (
            <div className="btn-container-disabled">
              <SendIcon className="send-btn2" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
