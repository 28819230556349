import cookie from "react-cookies";
import axios from "axios";
const profile = cookie.load("profile");
const apiUrl = "https://dev-chat.foxmatrix.com/fm_app"

const API = {
  GetChatbotResponse: async (message) => {
    const axiosConfig = {
      headers: {
        Authorization: `null`, // Replace with your actual token or remove this line
      },
    };

    if (
      message === "welcome msg" 
    ) {
      // return `Hey ${profile?.givenName} ${profile?.familyName}, How can I help you?`;
      return {response: "Hi Deepak! I am the Foxmatrix AI Chatbot, here to assist you with Interviewer Availability and Candidate Feedback. How can I help you today?"}
    }

    try {
      const response = await axios.post(
        apiUrl + '/availability_check/',
        { 
          query: message,
        },
        axiosConfig
      );

      const responseData = response.data;
      const botMessage = responseData.response;

      // You can customize the response based on specific conditions
      // if (message === "hi") {
      //   return `Hey ${profile?.givenName} ${profile?.familyName}, How can I help you?`;
      // } else {
      return botMessage;
      // }
    } catch (error) {
      console.error("Error:", error.message);
      return "An error occurred while fetching the response.";
    }
  },
  getAudioResponse: async (data) => {
    try {
      const axiosConfig = {
        headers: {
          Authorization: null,
        },
        responseType: 'blob'  
      };
      const res1 = await axios.post(
        apiUrl + "/text_interviewer_availability/",
        data,
        axiosConfig
      );
      return res1.data;
    } catch (error) {
      console.error("Error:", error.message);
      return "An error occurred while fetching the response.";
    }
  },

  getTextReponse: async (data) => {
    try {
      const axiosConfig = {
        headers: {
          Authorization: null,
        },
      };
      const res1 = await axios.post(
        apiUrl + "/audio_interviewer_availability/",
        data,
        axiosConfig
      );
      return res1.data;
    } catch (error) {
      console.error("Error:", error.message);
      return "An error occurred while fetching the response.";
    }
  },

  getPDFReponse: async (data) => {
    try {
      const axiosConfig = {
        headers: {
          Authorization: null,
        },
      };
      const res1 = await axios.post(
        "https://dev.services.foxmatrix.com/foxmatrixapp/v1/api/interviewer/getCandidateFeedback",
        data,
        axiosConfig
      );
      return res1.data;
    } catch (error) {
      console.error("Error:", error.message);
      return "An error occurred while fetching the response.";
    }
  },
};

export const getWelcomeMsg = async () => {
  const axiosConfig = {
    headers: {
      Authorization: `null`, // Replace with your actual token or remove this line
    },
  };
  try {
    const response = await axios.post(
      "https://qna.innovationm.com/api/upload/audio/",
      {
        id: "",
        response_text:
          !profile?.givenName && !profile?.familyName
            ? "Provide me one line welcome message like this:-  Hello ,Welcome!, How can i assist you today?"
            : "Provide me one line welcome message like this:-  Hello ,Welcome!, " +
              profile?.givenName +
              " " +
              profile?.familyName +
              " How can i assist you today?",
      },
      axiosConfig
    );
    return response?.data;
  } catch (error) {
    console.log("Error fetching welcome message", error);
    return "Error fetching welcome message";
  }
};

export default API;
